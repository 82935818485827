import config from "../../config.json"

export default new class ACS {

	Order(amount) {
		return new Promise((resolve, reject) => {

			/**
			 * Validate if the order amount exceed the minimum required
			 * order amount and return error in case of error
			 */
			if ( amount == null ) {
				reject("Invalid order amount")
				return
			} else {
				if ( amount <= 99 ) {
					reject("You need to reload at least Rs 100.00 /=")
					return
				}
			}

			fetch(config.services.acs.url + "/order", {
				method: "POST",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") },
				body: JSON.stringify({
					amount: parseFloat(amount)
				})
			}).then(Response => Response.json()).then(Response => {
				if (Response.status == "success" ) {
					resolve(Response.data.order)
				} else { ( Response.status == "failed" ) ? reject(Response.error) : reject(false) }
			}).catch(error => reject(error))

		})
	}

}
