<template>
	<div>
		<Navbar />
		<Header title="Billing" excerpt="Billing information for the account">
			<HeaderButton title="Reload" excerpt="You can reload credit to your Zend account" />
		</Header>

		<!-- PAGE CONTENT -->
		<b-container>
			<b-row>
				<b-col md="8">
					<b-card class="h-100">
						<b-card-body class="credit-card"></b-card-body>
					</b-card>
				</b-col>

				<!-- CHART -->
				<b-col md="4">
					<b-card class="h-100">
						<div class="b-card-body">

							<b-form>
								<div class="form-group">
									<label>Name</label>
									<b-form-input v-bind:value="this.name" disabled />
								</div>
								<div class="form-group">
									<label>Phone</label>
									<b-form-input v-bind:value="this.phone" disabled />
								</div>
								<div class="form-group">
									<label>Amount</label>
									<b-form-input v-model="amount" type="number" placeholder="100.00"  />
								</div>
								<b-button v-on:click="On_Pay" variant="primary" :disabled="this.button.disabled" block>Pay</b-button>
							</b-form>

							<!-- DIRECTPAY PAYMENT FORM -->
							<form id="directpay-form" class="d-none" method="POST" action="https://pay.directpay.lk/">
								<input type="text" name="_type" id="_type" value="ONE_TIME">
								<input type="hidden" name="_mId" id="_mId" value="RA07813">
								<input type="text" name="_amount" id="_amount" v-bind:value="parseFloat(this.order.amount).toFixed(2)" />
								<input type="text" name="_firstName" id="_firstName" v-bind:value="this.$store.state.user.name.first" />
								<input type="text" name="_lastName" id="_lastName" v-bind:value="this.$store.state.user.name.last" />
								<input type="text" name="_email" id="_email" v-bind:value="this.$store.state.user.email" />
								<input type="text" name="_reference" id="_reference" v-bind:value="order.ref" />
								<input type="text" name="_description" id="_description" v-bind:value="order.id" />
								<input type="hidden" name="_returnUrl" id="_returnUrl" value="https://app.zend.lk/billing" />
								<input type="hidden" name="_cancelUrl" id="_cancelUrl" value="https://app.zend.lk/billing" />
								<input type="hidden" name="_responseUrl" id="_responseUrl" value="https://acs.service.zend.lk/cb/ipg/directpay" />
								<input type="text" name="_currency" id="_currency" value="LKR" />
								<input type="text" name="_orderId" id="_orderId" v-bind:value="order.id" />
								<input type="hidden" name="_pluginVersion" id="_pluginVersion" value="1.0" />
								<input type="hidden" name="_pluginName" id="_pluginName" value="CUSTOM" />
								<input type="hidden" name="api_key" id="api_key" value="130a12a76b68545cb7128ad334288980" />
								<input name="signature" id="signature" v-bind:value="order.signature" />
							</form>

						</div>
					</b-card>
				</b-col>

			</b-row>
		</b-container>

	</div>
</template>

<script>
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";
import HeaderButton from "@/components/Header/Button";

// Services
import ACS from "@/services/acs"

export default {
	name: "Billing",
	components: { Navbar, Header, HeaderButton },

	data: () => ({
		amount: null,
		order: {
			id: null,
			ref: null,
			amount: null,
			signature: null
		},
		button: {
			disabled: false
		}
	}),

	mounted() {
		this.$store.dispatch("user/initialize")
	},

	computed: {
		name: function() { return this.$store.state.user.name.first + " " + this.$store.state.user.name.last },
		phone: function() { return "+94" + this.$store.state.user.mobile },
	},

	methods: {

		On_Pay: function() {
			this.button.disabled = true

			/**
			 * We have to publish new payment information to the
			 * ACS service and it'll create new order. Then we
			 * can proceed to redirect into order processing page
			 */
			ACS.Order(this.amount).then(Response => {

				/**
				 * Seems like order got placed without any issues from the
				 * ACS service and now we need to redirect user into the
				 * payment prompt of the recently place order.
				 */
				this.order.id = Response.id
				this.order.ref = Response.ref
				this.order.amount = Response.amount
				this.order.signature = Response.signature
				setTimeout(() => { document.getElementById("directpay-form").submit() }, 1000)

			}).catch(error => {
				this.$swal({
					text: error,
					icon: "error",
					title: "Error"
				})
				this.button.disabled = false
			})

		}

	}

}
</script>

<style lang="scss" scope>
.credit-card {
	text-align: center;

	.title {
		font-size: 15px;
		font-weight: 600;
		color: $text-dark-secondary;
		letter-spacing: 3px;
	}

	h1 {
		margin-top: 50px;
		font-size: 35px !important;
		font-weight: 400 !important;
		color: $text-primary;
	}

	.appx {
		margin-top: 20px;
		font-weight: 600;
		font-size: 14px;
		color: $text-secondary;
	}
}
</style>
