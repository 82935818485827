<template>
	<div class="col-3 pl-3 pr-0">
		<router-link :to="{ path: route }">
		<div class="media px-2 h-100 rounded">
			<img class="img-fluid align-self-center mr-3" src="@/assets/icons/credit-card.png" />
			<div class="media-body align-self-center">
				<h5 class="mt-0 mb-0">{{ title }}</h5>
				<p class="mb-0">{{ excerpt }}</p>
			</div>
		</div>
		</router-link>
	</div>
</template>

<script>
export default {
	name: "HeaderButton",
	props: ["title", "excerpt", "route"]
}
</script>

<style lang="scss" scoped>
.media {
	cursor: pointer;
	border: 2px solid #0062ff;

	&:hover {
		color: #ffffff;
		background-color: #0062ff;
	}

	h5 {
		font-size: 16px;
	}

	p {
		font-size: 9px;
	}
}
</style>
